.nav-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(90deg, #1b68fb 0%, #1b83fc 50%, #1b9dfc 100%);
  background: #3875f6;
  display: flex;
  min-width: 1400px;
  z-index: 1000;
  .logo {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  .logo .img {
    width: auto;
    height: 26px;
    margin-top: 18px;
  }
  .logo .text {
    font-size: 18px;
    line-height: 60px;
    color: #fff;
    margin-left: 10px;
  }
  .header-center {
    width: 1200px;
    display: flex;
    justify-content: space-between;
  }
  .nav-wrap {
    display: flex;
    float: left;
  }
  .nav-wrap .nav-cell {
    font-size: 18px;
    line-height: 60px;
    padding: 0 16px;
    color: #fff;
    transition: background-color .3s;
    cursor: pointer;
  }
  .nav-wrap .nav-cell:hover {
    background: rgba(255, 255, 255, .2);
  }
  .nav-wrap .nav-cell.active {
    background: rgba(255, 255, 255, .2);
  }
  .message-button {
    float: left;
    padding: 15px 0;
    color: #fff;
    cursor: pointer;
    margin-left: 30px;
  }
  .message-button .button {
    position: relative;
    font-size: 18px;
    line-height: 30px;
    color: #fff;
  }
  .message-button .message-tips {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    line-height: 18px;
    background-color: #ff5555;
    border-radius: 9px;
    padding: 0 5px;
    transform: translateX(84%);
  }
  .message-button .arrow {
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    left: 32px;
    top: -5px;
    transform: rotate(45deg);
  }
  .message-button .el-dropdown-menu {
    border-radius: 5px;
  }
  .message-list {
    width: 276px;
    max-height: 250px;
    overflow: auto;
    padding: 20px;
    background: #fff;
    font-size: 14px;
    line-height: 20px;
    color: #778b99;
  }
  .message-list .list {
    cursor: pointer;
    margin-bottom: 8px;
    transition: color .2s;
  }
  .message-list .list:active {
    color: rgba(119, 139, 153, 0.7);
  }
  /*
  .button-group {
    float: right;
  }
  */
  .search-form {
    width: 280px;
    height: 40px;
    float: left;
    margin-top: 10px;
    position: relative;
  }
  .search-form .iconfont {
    position: absolute;
    left: 12px;
    top: 10px;
    font-size: 17px;
    line-height: 1;
    color: #fff;
  }
  .search-form .input {
    width: 100%;
    height: 100%;
    padding: 8px 12px 8px 34px;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    background: none;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0);
    transition: border-color .2s;
  }
  .search-form .input:focus {
    border-color: rgba(255, 255, 255, 0.6);
  }
  .search-form .input::-webkit-input-placeholder {
    color: #fff;
  }
  .add-resume-button {
    float: left;
    width: 109px;
    height: 40px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    margin-top: 10px;
    margin-left: 40px;
    cursor: pointer;
    transition: background .2s;
  }
  .add-resume-button:hover {
    background: rgba(255, 255, 255, 0.4);
  }
  .add-resume-button:active {
    background: rgba(255, 255, 255, 0.3);
  }
  .add-resume-button .iconfont {
    font-size: 16px;
    line-height: 40px;
    margin-right: 4px;
  }
  .header-right {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .header-right .head-img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    overflow: hidden;
    margin-top: 8px;
    margin-right: 8px;
  }
  .header-right .head-img .img {
    width: 100%;
    height: 100%;
  }
  .header-right .dropdown-link {
    font-size: 16px;
    line-height: 30px;
    color: #fff;
    margin-top: 15px;
    cursor: pointer;
  }
  .header-right .dropdown-link:hover {
    opacity: .9;
  }
  .header-right .dropdown-link:active {
    opacity: .8;
  }
  .header-right .el-icon-arrow-down {
    font-size: 12px;
  }
  .header-right .el-dropdown-menu {
    border-radius: 5px;
  }
  
  @media only screen and  (max-width: 1600px) {
    .search-form{
      width: 120px;
    }
    .header-center {
      width: 1000px;
    }
    .add-resume-button {
      margin-left: 10px;
    }
  }
}
