.user_baseinfo_edit_cascader_popup{
    .ant-cascader-menu .ant-cascader-menu-item-expand{

        .ant-cascader-checkbox{
            pointer-events: none;
            display: none;
            //将文字变蓝表示有字节点被选中
            &.ant-cascader-checkbox-indeterminate,&.ant-cascader-checkbox-checked{
                &+.ant-cascader-menu-item-content{
                    color: #1677ff;
                }
            }
        }
    }
}