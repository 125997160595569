.image-carousel {
    position: relative;
    width: 100%;
    &-container {
      display: flex;
      overflow: hidden;
      white-space: nowrap;
      scroll-behavior: smooth;
    }
    &-item{
      display: block;
      flex-shrink: 0;
      cursor: pointer;
      position: relative;
      &-content{
        position: absolute;
        top:20px;
        left:20px;
        width: 80%;
        display: flex;
        flex-direction: column;
        color: #fff;
        .t1{
          font-size: 18px;
          font-weight: bold;
        }
        .t2{
          font-size: 14px;
          white-space: wrap;
        }
      }
    }
}
.image-carousel-opt{
  display: flex;
  justify-content: center;
  .image-carousel-button {
    color: rgba(0,0,0,.45);
    cursor: pointer;
    &.left {
      left: 0;
      width: 64px;
    }
    &.right {
      right: 0;
    }
  }
}
  