.page-jobs {
  .search-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 172px;
    background: #fff;
    z-index: 1;
    animation: fadeIn .1s ease-in;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .search-top {
    display: flex;
    margin-top: 20px;
    .el-form-item{
        margin-bottom: 0;
        margin-right: 20px;
    }
    .el-input {
      flex: 1;
      margin-right: 20px;
    }
  }
  .search-area {
    font-size: 14px;
    line-height: 30px;
    color: #666;
    margin-top: 10px;
    overflow: hidden;
    flex-wrap: wrap;
    .name {
      margin-right: 26px;
      float: left;
    }
    .item {
      float: left;
      padding: 0 7px;
      margin-right: 20px;
      cursor: pointer;
      transition: background .2s, color .2s;
      flex-wrap: wrap;
      &.active {
        background: linear-gradient(45deg, #1b9dfc, #1bc5fd);
        border-radius: 4px;
        color: #fff;
      }
    }
    .hasBorder{
      border: 1px solid #bfcbd9;
      border-radius: 5px;
      padding: 0 10px;
    }
  }
  .search-other {
    margin-top: 20px;
    display: flex;
    .item {
      color: #48576a;
      margin-right: 40px;
      .el-select {
        margin: 0 10px;
      }
      .el-input__inner {
        height: 40px;
      }
      .el-input {
        margin: 0 10px;
        width: 120px;
      }
      .el-dropdown-menu {
        top: 40px !important;
      }
    }
    .dropdown-link {
      font-size: 16px;
      line-height: 1;
      padding: 12px 0;
      .el-icon-caret-bottom {
        margin-left: 4px;
        color: #778b99;
        font-size: 12px;
      }
    }
  }

  .page-content {
    display: flex;
    margin-top: 20px;

    .block-left {
      flex: 1;
      .empty-tips {
        text-align: center;
        font-size: 14px;
        color: #5e7382;
        padding: 75px 0;
        background: #fff;
        width: 100%;
        position: relative;
        box-shadow: 0 1px 27px 0 rgba(33, 92, 136, 0.09);
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 20px;
      }
      .block-pagination {
        text-align: center;
        margin-bottom: 50px;
      }
    }
    .block-right {
      margin-left: 20px;
    }
  }
  .dialog-notice{
    display: flex;
    align-items: center;
  }
  .dialog-content{
    margin-left: 10px;
    p{
      color: #333333;
      font-size: 16px;
      line-height: 30px;
    }
  }
}
.hotIcon{
  width: 80px;
  height: 80px;
  position: fixed;
  bottom: 120px;
  right: 45px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1b83fc;
  box-shadow: 0 2px 9px 0 rgba(105, 140, 165, 0.24);
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;

}
.hotBox{
  height: 60vh;
  overflow-y: auto;
  padding-right: 10px;
  color: #333333;
  font-size: 14px;
  h4{
    text-align: center;
    font-size: 16px;
    position: sticky;
    top:0;
    background: #ffffff;
    padding-bottom: 10px;
  }

  &-title{
    color: #333333;
    font-size: 14px;
    padding-bottom: 6px;
    padding-top: 10px;
    font-weight: 600;
  }
  &-hotimgs{
    margin: 10px 0;
    display: flex;
    justify-content: center;
    img{
      width: 300px;
      margin: 0 4px;
    }

  }
  &-texts{
    text-indent: 2rem;
    text-align: justify;
    text-align-last: left;
  }
  &-import{
    color: red;
    font-weight: 500;
    padding: 10px 0;
  }
  .flexCentet{
    margin: 10px 0;
    display: flex;
    justify-content: center;

    img{
      width: 300px;

    }
  }
}

