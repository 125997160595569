html {
  color: #000;
  background: #f6f8f9;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 16px;
  min-width: 1400px;
  font-family: 'PingFangHk-Regular', 'Helvetica', 'Roboto', 'Microsoft YaHei';
}
html * {
  outline: none;
  box-sizing: border-box;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  margin: 0;
  padding: 0
}
input, select, textarea {
  font-size: 100%;
}
input,button,textarea{
  background-color: inherit;
  color: inherit;
  font: inherit;
  border: none;
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0
}
fieldset, img {
  border: 0
}
abbr, acronym {
  border: 0;
  font-variant: normal
}
del {
  text-decoration: line-through
}
address, caption, cite, code, dfn, em, th, var {
  font-style: normal;
  font-weight: 500
}
ol, ul {
  list-style: none
}
caption, th {
  text-align: left
}
h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: 500
}
q:before, q:after {
  content: ''
}
sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}
sup {
  top: -.5em
}
sub {
  bottom: -.25em
}
a{text-decoration:none;cursor: pointer;}
a:hover{text-decoration:underline;}
a,
a:focus,
a:hover,
a:active{ outline:none; color:inherit; text-decoration:none;}
ins{
  text-decoration: none
}
img{
  max-width: 100%;
  vertical-align: middle;
}
:root {
  --ant-primary-color: #1b9dfc;
}

.ant-btn-primary {
  background-color: var(--ant-primary-color);
  border-color: var(--ant-primary-color);
}

.ant-btn-primary:hover {
  background-color: #16976b;
  border-color: #16976b;
}

@font-face {
  font-family: 'iconfont';  /* project id 1183720 */
  src: url('//at.alicdn.com/t/font_1183720_q7k65w9hvv.eot');
  src: url('//at.alicdn.com/t/font_1183720_q7k65w9hvv.eot?#iefix') format('embedded-opentype'),
  url('//at.alicdn.com/t/font_1183720_q7k65w9hvv.woff2') format('woff2'),
  url('//at.alicdn.com/t/font_1183720_q7k65w9hvv.woff') format('woff'),
  url('//at.alicdn.com/t/font_1183720_q7k65w9hvv.ttf') format('truetype'),
  url('//at.alicdn.com/t/font_1183720_q7k65w9hvv.svg#iconfont') format('svg');
}
.iconfont{
  font-family:"iconfont" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.single-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.multi-text-over{
  display: -webkit-box;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp:2;
}
/* 重置element-ui部分样式 */
.el-message-box {
  .el-message-box__header {
    padding: 56px 20px 0;
  }
  .el-message-box__title {
    text-align: center;
    font-size: 18px;
  }
  .el-message-box__content {
    padding: 20px 36px 30px;
    color: #778b99;
  }
  .el-message-box__btns {
    text-align: center;
    padding: 10px 20px 45px;
  }
  .el-button {
    height: 40px;
    border-radius: 4px;
    font-size: 18px;
    line-height: 1;
    color: #fff;
    padding: 11px 10px;
    transition: background .2s;
    min-width: 120px;
    border: none;
  }
  .el-button--default, .el-button--default:focus {
    background: #778b99;
    color: #fff;
    &:hover {
      background: #5c717f;
      color: #fff;
    }
    &:active {
      background: #4b6475;
      color: #fff;
    }
  }
  .el-button--primary {
    background: linear-gradient(45deg, #1b9dfc, #1bc5fd) !important;
    &:hover {
      background: linear-gradient(45deg, #0673ee, #06b5ee) !important;
    }
    &:active {
      background: linear-gradient(45deg, #0647ee, #06a4ee) !important;
    }
  }
}
.el-message {
  z-index: 1001;
}
.el-steps {
  .el-step:nth-last-child(1) {
    width: auto !important;
  }
  .el-step__head {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .el-step__head.is-text {
    font-size: 20px;
    border: none;
  }
  .el-step__head.is-text.is-wait {
    background: #dde2e6;
    color: #fff;
  }
  .el-step__head.is-text.is-process {
    background: linear-gradient(45deg, #1b9dfc, #1bc5fd);
  }
  .el-step__icon {
    line-height: 50px;
  }
  .el-step__line.is-horizontal {
    top: 25px;
    left: 50px;
    background: #dde2e6;
  }
  .el-step__line-inner {
    width: 100% !important;
    background: #2ac16e;
  }
}
.el-input {
  .el-input__inner {
    border-color: #d0d8dd;
    height: 40px;
    &:focus {
      border-color: #1bc5fd;
    }
  }
  &.is-disabled .el-input__inner {
    background-color: #eef2f4 !important;
    border-color: #eef2f4 !important;
    color: #768998 !important;
  }
}
.el-form {
  .el-form-item__label {
    padding: 13px 12px 13px 0;
  }
}
.el-table {
  border: none !important;
  color: #6a8190 !important;
  .el-table__footer-wrapper thead div, .el-table__header-wrapper thead div {
    color: #6a8190;
  }
  .el-table__empty-block {
    border-bottom: 1px solid #dfe6ec;
  }
  .show-overflow-tooltip {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.el-table::after, .el-table::before {
  content: none !important;
}
.el-table__body-wrapper {
  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    background: #dfe6ec;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}
.text-no-wrap {
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.color-primary {
  color: #1b9dfc;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@for $i from 1 through 100 {
  .mr-#{$i} {
    margin-right: $i * 1px;
  }
  .mt-#{$i} {
    margin-top: $i * 1px;
  }
  .mb-#{$i} {
    margin-bottom: $i * 1px;
  }
  .ml-#{$i} {
    margin-left: $i * 1px;
  }
}
.single-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.multi-line-text-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 这里设置最大显示的行数为2 */
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em; /* 根据实际情况调整行高 */
  max-height: 3em; /* 行高 * 行数，确保不超过两行的高度 */
}
.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}
.text-dim{
  filter:blur(3px);
}
.img-dim{
  filter:blur(20px);
}
.secondary_antd_tab {
  .ant-tabs-nav{
    border-bottom: 1px solid #d1dbe5;
  }
}
em {
  font-style: italic;
}
.link-text{
  color: #1677ff;
  cursor: pointer;
  :hover{
      color: #69b1ff;
  }
}
.table_operate_btns{
  .ant-btn{
      padding-left: 0;
      &:last-child{
          padding-right: 0;
      }
  }
}
.head-submenu-active{
  color: #1677ff;;
}